<template>
  <div
    class="songCardContainer"
    :style="{ backgroundImage: `url(${this.thumbnailUrl})` }"
    @click="playSong"
  >
    <div class="text">
      <h3>{{ songDesc.title }}</h3>
      <h6>{{ songDesc.artist }}</h6>
    </div>
    <div class="heartsongCardContainer" @click.stop="toggleFavorite">
      <HeartSymbol :isFavorite="this.isFavorite"></HeartSymbol>
    </div>
  </div>
</template>

<script>
import HeartSymbol from "@/components/HeartSymbol.vue";

export default {
  name: "SongCard",
  props: {
    songDesc: Object,
    favorites: Array,
  },
  // data() {
  //   return {
  //     isFavorite: false,
  //   };
  // },
  components: {
    HeartSymbol,
  },
  computed: {
    isFavorite() {
      return this.favorites.some(
        (item) =>
          item.id === this.songDesc.id && item.title === this.songDesc.title
      );
    },

    thumbnailUrl() {
      return "https://img.youtube.com/vi/" + this.songDesc.id + "/0.jpg";
    },
  },
  methods: {
    toggleFavorite() {
      this.$emit("toggle-fav", this.songDesc);
    },
    playSong() {
      // document.documentElement.requestFullscreen();
      let x = this.songDesc.file.replace(".txt", "");
      this.$router.push({ path: `/SongView/${x}/${this.songDesc.id}` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heartsongCardContainer {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 10%;
  aspect-ratio: 1;
}
.text {
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  padding: 2%;
}
h6 {
  margin: 0;
}
h3 {
  margin: 0;
}
.songCardContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: var(--main-bg-color);
  color: var(--main-font-color);
  margin: 10px;
  width: 200px;
  height: 200px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* border: 10px solid gray; */
  aspect-ratio: 1;
}
.songCardContainer:hover {
  transform: scale(1.05);
  cursor: pointer;
}
</style>

<template>
  <div v-auto-animate>
    <input
      v-model="searchTerm"
      @change="searchChanged"
      placeholder="Search Songs..."
    />
    <label for="language-select">language:</label>
    <select
      id="language-select"
      v-model="selectedLanguage"
      @change="saveSelection"
    >
      <option value="all">All</option>
      <option value="english">English</option>
      <option value="spanish">Spanish</option>
      <option value="german">German</option>
    </select>
    <label for="checkbox">favorites</label>
    <input type="checkbox" id="checkbox" v-model="favView" />
    <button @click="showPlayerManager = !showPlayerManager">
      Players {{ showPlayerManager ? "⬆️" : "⬇️" }}
    </button>
    <PlayerManager v-if="showPlayerManager" />
  </div>
  <div class="container" @scroll="handleScroll" ref="scrollContainer">
    <SongCard
      v-for="(n, index) in this.visibleSongs"
      :songDesc="n"
      :favorites="this.favorites"
      @toggle-fav="toggleFavorite"
      :key="index"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import SongCard from "@/components/SongCard.vue";
import PlayerManager from "@/components/PlayerManager.vue";

export default {
  name: "HomeView",
  components: {
    SongCard,
    PlayerManager,
  },
  methods: {
    searchChanged() {
      this.songDisplayCount = 200;
      sessionStorage.setItem("searchTerm", this.searchTerm);
    },
    toggleFavorite(song) {
      const isFavorite = this.favorites.some(
        (item) => item.id === song.id && item.title === song.title
      );

      if (isFavorite) {
        this.favorites = this.favorites.filter(
          (item) => item.id !== song.id || item.title !== song.title
        );
      } else {
        this.favorites.push(song);
      }
      localStorage.setItem("favorites", JSON.stringify(this.favorites));
    },
    saveSelection() {
      // Save the selected language to localStorage
      localStorage.setItem("selectedLanguage", this.selectedLanguage);
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 5 &&
        !this.loading
      ) {
        // this.loadItems();
        this.songDisplayCount += 50;
      }
    },
  },
  computed: {
    filteredSongs() {
      //could be optimized (... should be optimized)
      return this.languageFilteredSongs.filter(
        (item) =>
          item.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          item.artist.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    languageFilteredSongs() {
      if (this.selectedLanguage == "all") {
        return this.activeSongs;
      } else {
        return this.activeSongs.filter(
          (item) => item.language.toLowerCase() == this.selectedLanguage
        );
      }
    },
    visibleSongs() {
      return this.filteredSongs.slice(0, this.songDisplayCount);
    },
    activeSongs() {
      return this.favView ? this.favorites : this.songs;
    },
  },
  data() {
    return {
      favView: false,
      showPlayerManager: false,
      songs: [],
      searchTerm: "",
      favorites: [],
      songDisplayCount: 200,
      selectedLanguage: "all",
    };
  },
  created() {
    // Load the saved selection from localStorage when the component is created
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
    }
    const savedSearchterm = sessionStorage.getItem("searchTerm");
    if (savedSearchterm) {
      this.searchTerm = savedSearchterm;
    }
    const savedSDC = sessionStorage.getItem("songDisplayCount");
    if (savedSDC) {
      this.songDisplayCount = parseInt(savedSDC);
    }
    const savedFavView = sessionStorage.getItem("favView");
    if (savedFavView) {
      this.favView = JSON.parse(savedFavView);
    }
    const existingArray = localStorage.getItem("favorites");
    this.favorites = existingArray ? JSON.parse(existingArray) : [];
  },
  mounted() {
    this.$refs.scrollContainer.addEventListener("scroll", this.handleScroll);
    const savedPosition = sessionStorage.getItem("scrollPosition");
    fetch("/Data2.json")
      .then((response) => response.json())
      .then((data) => {
        this.songs = data;
        if (savedPosition) {
          this.$nextTick(() => {
            this.$refs.scrollContainer.scrollTop = parseInt(savedPosition, 10);
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  },
  beforeUnmount() {
    this.$refs.scrollContainer.removeEventListener("scroll", this.handleScroll);
    sessionStorage.setItem(
      "scrollPosition",
      this.$refs.scrollContainer.scrollTop
    );
    sessionStorage.setItem("songDisplayCount", this.songDisplayCount);
    sessionStorage.setItem("favView", JSON.stringify(this.favView));
  },
};
</script>

<style>
body {
  background-color: var(--second-bg-color);
}
.container {
  /* margin: 0 auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  overflow-y: auto;
  height: 90vh;
  /* overflow: scroll;
  max-height: 80vh; */
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

createApp(App)
  .use(VuePlyr, {
    plyr: {},
  })
  .use(router)
  .use(autoAnimatePlugin)
  .mount("#app");

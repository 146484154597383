<template>
  <div class="heartSymbol">
    <span class="heart">
      <svg>
        <use xlink:href="#heart"></use>
      </svg>
    </span>
    <span class="layer" :class="{ favorite: isFavorite }">
      <svg>
        <use xlink:href="#heart"></use>
      </svg>
    </span>
    <svg version="1.1" id="heartSymbol" xmlns="http://www.w3.org/2000/svg">
      <symbol id="heart" viewBox="0 0 50 50">
        <path
          stroke="#ffffff"
          stroke-width="2"
          style="fill: currentColor"
          d="M24.85,10.126c2.018-4.783,6.628-8.125,11.99-8.125c7.223,0,12.425,6.179,13.079,13.543
    c0,0,0.353,1.828-0.424,5.119c-1.058,4.482-3.545,8.464-6.898,11.503L24.85,48L7.402,32.165c-3.353-3.038-5.84-7.021-6.898-11.503
    c-0.777-3.291-0.424-5.119-0.424-5.119C0.734,8.179,5.936,2,13.159,2C18.522,2,22.832,5.343,24.85,10.126z"
        />
      </symbol>
    </svg>
  </div>
</template>

<script>
export default {
  name: "HeartSymbol",
  props: {
    isFavorite: Boolean,
  },
  //   data() {
  //     return {
  //       isFavorite: true,
  //     };
  //   },
  //   methods: {
  //     toggleHeart() {
  //       this.isFavorite = !this.isFavorite;
  //     },
  //   },
};
</script>

<style scoped>
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
svg {
  width: 100%;
  height: 100%;
}
.heartSymbol {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.heartSymbol .heart {
  width: 100%;
  height: 95%;
  display: inline-block;
  cursor: pointer;
  animation-duration: 0.45s;
  animation-timing-function: ease;
  animation-direction: alternate;
}
.heartSymbol .heart svg {
  fill: transparent;
  color: transparent;
  transition: all 0.55s ease;
}
.heartSymbol .layer {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 95%;
  display: inline-block;
  transform: scale(0);
  pointer-events: none;
}
.heartSymbol .layer svg {
  fill: #ffffff;
  color: #ffffff;
}
.favorite {
  transform: scale(1) !important;
  opacity: 1;
  transition: transform 0.35s ease, opacity 0.35s ease;
}
</style>

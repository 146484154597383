<template>
  <div class="playerCard">
    <input v-model="pm.players[index].state.pname" />
    <div v-if="!isConnected && pm.players[index].type == 'remote'">
      <div v-html="qrCodeSVG"></div>
      <p>
        <a :href="joinlink">{{ joinlink }}</a>
      </p>
    </div>
    <div
      v-if="pm.players[index].type == 'local' || isConnected"
      class="color-palette"
    >
      <label
        v-for="color in pm.players[index].optionalColors"
        :key="color"
        class="color-circle"
      >
        <input
          type="radio"
          :value="color"
          v-model="pm.players[index].state.color"
          class="color-radio"
        />
        <span class="circle" :style="{ backgroundColor: color }"></span>
      </label>
    </div>
    <div v-if="pm.players[index].type == 'local'">
      <h1>🎙️</h1>
      <select id="mic-select" @change="selectMic">
        <option
          v-for="(n, i) in pm.players[index].microphoneList"
          :value="n.deviceId"
          :key="i"
          :selected="n.deviceId == pm.players[index].currentMicId"
        >
          {{ n.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import pm from "@/components/PlayerManager.js";
// import QRCode from "davidshimjs-qrcodejs";
import QRCode from "qrcode-svg";

export default {
  name: "PlayerCard",
  data() {
    return {
      pm,
      selectedColor: "#ff0000", // Default color
    };
  },
  props: {
    roomId: String,
    isConnected: Boolean,
    index: Number,
  },
  methods: {
    selectMic(event) {
      pm.players[this.index].setMic(event.target.value);
    },
  },
  computed: {
    joinlink() {
      return window.location.origin + "/join/" + this.roomId;
    },
    qrCodeSVG() {
      const qrcode = new QRCode({
        content: this.joinlink,
        container: "svg-viewbox", //Responsive use
        join: true, //Crisp rendering and 4-5x reduced file size
        color: "#ffffff",
        background: "rgba(0,0,0,0)",
        ecl: "L",
      });
      return qrcode.svg();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  color: var(--main-font-color);
}
.playerCard {
  width: 300px;
  font-family: "Courier New", Courier, monospace;
  background: var(--main-bg-color);
  color: var(--main-font-color);
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.playerCard input {
  text-align: center;
  color: white;
  background: transparent;
  border: 1px solid lightgray;
  font-size: 2em;
  width: 90%;
  padding: 0;
  margin: 5%;
}
.playerCard select {
  width: 90%;
}

.color-palette {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px; /* Space between circles */
  padding: 20px;
}

.color-circle {
  display: flex;
  align-items: center;
  cursor: pointer; /* Change cursor to pointer on hover */
}

.color-radio {
  display: none; /* Hide the default radio button */
}

.circle {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%; /* Make it a circle */
  border: 3px solid transparent; /* Default border */
  transition: border 0.3s; /* Smooth transition for border */
}

.color-radio:checked + .circle {
  border: 3px solid white;
}
</style>

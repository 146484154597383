<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/lyricsView">Lyrics</router-link>
  </nav> -->
  <router-view />
</template>

<style>
:root {
  --main-bg-color: black;
  --second-bg-color: #181a1b;
  --main-color: lightblue;
  --main-font-color: white;
}

body {
  background-color: var(--main-bg-color);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--main-font-color);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

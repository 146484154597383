<template>
  <div class="playerContainer" v-auto-animate>
    <Playercard
      v-for="(n, index) in pm.players"
      :key="index"
      :roomId="n.state.roomId"
      :isConnected="n.state.isConnected"
      :index="index"
    />
    <div class="newPlayerContainer">
      <div>
        <button @click="pm.addPlayer()">add remote📱</button>
        <button @click="pm.addLocalPlayer()">add local🎙️</button>
        <button @click="pm.removePlayer()">remove</button>
      </div>
    </div>
  </div>
</template>

<script>
import pm from "@/components/PlayerManager.js";
import Playercard from "@/components/PlayerCard.vue";

export default {
  name: "PlayerManager",
  data() {
    return {
      pm,
    };
  },
  components: {
    Playercard,
  },
  // methods: {
  // },
  // props: {
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.playerContainer {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
}
.newPlayerContainer {
  width: 300px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button {
  cursor: pointer;
  margin: 20px auto;
  /* width: 100px; */
  display: block;
  width: 100%;
  font-size: 2em;
  border-radius: 50px;
  color: var(--main-font-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: var(--main-bg-color);
}
</style>

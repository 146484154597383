import { io } from "socket.io-client";
import { reactive } from "vue";

class PlayerManager {
  constructor() {
    this.players = [];
    this.addLocalPlayer();
  }

  addPlayer() {
    const newPlayer = new RemotePlayer();
    this.players.push(newPlayer);
  }
  addLocalPlayer() {
    this.players.push(new LocalPlayer());
  }
  removePlayer() {
    this.players.pop().destroy();
  }
}
class Player {
  static optionalColors = [
    "#ADD8E6", // Light Blue
    "#E0FFFF", // Light Cyan
    "#90EE90", // Light Green
    "#F08080", // Light Coral
    "#FAFAD2", // Light Goldenrod Yellow
    "#FFB6C1", // Light Pink
    "#E6E6FA", // Lavender
    "#FFDAB9", // Peach Puff
  ];
  type;
  callback = null;
  constructor() {
    this.state = reactive({
      pname: "Player",
      color: this.optionalColors[0],
      isConnected: false,
    });
  }
  pitchUpdate(cb) {
    this.callback = cb;
  }
  get optionalColors() {
    return Player.optionalColors;
  }
}

class RemotePlayer extends Player {
  type = "remote";
  dataChannel = null;
  constructor() {
    super();
    this.socket = io.connect(process.env.VUE_APP_SIGNALING_SERVER);
    this.pc = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302", // Google's public STUN server
        },
      ],
    });
    this.state.roomId = null;

    this.setupSocketListeners();
    this.socket.emit("createRoom");
  }

  destroy() {
    this.socket.disconnect();
    this.socket.removeAllListeners();
    this.pc.close();
    this.pc = null;
    this.socket = null;
    this.callback = null;
  }
  startPostingData() {
    if (this.dataChannel.readyState === "open") {
      this.dataChannel.send("start");
    } else {
      console.error("Data channel is not open");
      console.log(this.dataChannel.readyState);
    }
  }

  stopPostingData() {
    if (this.dataChannel.readyState === "open") {
      this.dataChannel.send("stop");
    } else {
      console.error("Data channel is not open");
      console.log(this.dataChannel.readyState);
    }
  }

  setupSocketListeners() {
    this.socket.on("roomCreated", (roomId) => {
      console.log("Room created with ID:", roomId);
      this.state.roomId = roomId;

      // Handle incoming offer
      this.socket.on("offer", (offer) => {
        console.log(offer);
        this.pc
          .setRemoteDescription(new RTCSessionDescription(offer))
          .then(() => this.pc.createAnswer())
          .then((answer) => this.pc.setLocalDescription(answer))
          .then(() => {
            this.socket.emit("answer", {
              roomId,
              answer: this.pc.localDescription,
            });
          });
      });

      // Handle ICE candidates
      this.pc.onicecandidate = (event) => {
        if (event.candidate) {
          this.socket.emit("candidate", { roomId, candidate: event.candidate });
        }
      };

      // Handle incoming candidate
      this.socket.on("candidate", (candidate) => {
        this.pc.addIceCandidate(new RTCIceCandidate(candidate));
      });

      this.socket.on("error", (message) => {
        console.error(message);
      });

      // DataChannel for sensor data
      this.pc.ondatachannel = (event) => {
        if (this.dataChannel) {
          this.dataChannel.close();
        }
        this.dataChannel = event.channel;

        this.dataChannel.onopen = () => {
          this.state.isConnected = true;
          console.log("Data channel is open");
        };

        this.dataChannel.onmessage = (event) => {
          // console.log("Received sensor data:", event.data);
          if (this.callback) {
            this.callback(event.data);
          }
        };
      };

      // Handle incoming answer
      this.socket.on("answer", (answer) => {
        this.pc.setRemoteDescription(new RTCSessionDescription(answer));
      });
    });
  }
}

class LocalPlayer extends Player {
  static audioContext = new window.AudioContext({ latencyHint: "interactive" });
  static microphoneList = reactive([]); // Make microphoneList reactive
  type = "local";
  currentMicId = reactive(null); // Property for the currently selected microphone ID
  analyserNode = null; // Property for the AnalyserNode
  stream = null; // Default stream
  worker = null; // Worker instance
  dataArray = new Float32Array(2048); // Array to hold analyser data
  intervalId = null; // ID for the interval
  intervalTime = 20;

  constructor() {
    super();
    this.worker = new Worker(new URL("pitchworker.js", import.meta.url));
    this.worker.onmessage = (event) => {
      if (this.callback) {
        this.callback(event.data);
      }
    };
    this.init();
  }
  get microphoneList() {
    return LocalPlayer.microphoneList;
  }

  destroy() {
    this.stopPostingData();
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
    this.worker.terminate();
  }

  async init() {
    await this.requestMicrophonePermission();
    await this.getMicrophones();
  }

  async requestMicrophonePermission() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Microphone permission granted.");

      // Set the currentMicId from the stream's audio track
      const audioTrack = this.stream.getAudioTracks()[0];
      if (audioTrack) {
        this.currentMicId = audioTrack.getSettings().deviceId; // Set currentMicId from the audio track
        console.log(`Current microphone ID set to: ${this.currentMicId}`);
      }

      // Create AnalyserNode and connect it
      this.analyserNode = LocalPlayer.audioContext.createAnalyser();
      const source = LocalPlayer.audioContext.createMediaStreamSource(
        this.stream
      );
      source.connect(this.analyserNode); // Connect source to analyserNode

      // this.startPostingData();
    } catch (error) {
      console.error("Microphone permission denied:", error);
      alert("Microphone permission not granted.");
    }
  }

  async getMicrophones() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    LocalPlayer.microphoneList.splice(
      0,
      LocalPlayer.microphoneList.length,
      ...devices.filter((device) => device.kind === "audioinput")
    );

    if (LocalPlayer.microphoneList.length === 0) {
      console.warn("No microphones found.");
      return;
    }

    LocalPlayer.microphoneList.forEach((mic) => {
      console.log(
        `Microphone found: ${mic.label || `Microphone ${mic.deviceId}`}`
      );
    });
  }

  async setMic(micId) {
    if (!micId) {
      console.error("No microphone ID provided.");
      return;
    }

    try {
      // Stop the current stream if it exists
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop());
      }

      // Get the new microphone stream
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: micId },
      });
      const source = LocalPlayer.audioContext.createMediaStreamSource(
        this.stream
      );
      source.connect(this.analyserNode); // Connect source to analyserNode

      this.currentMicId = micId; // Update the currently selected microphone ID
      console.log(`Switched to microphone: ${micId}`);

      // Restart posting data if the analyserNode is valid
      // if (this.analyserNode) {
      //   this.startPostingData();
      // }
    } catch (error) {
      console.error(`Error accessing microphone ${micId}:`, error);
      alert(
        `Could not access microphone with ID ${micId}. Please check permissions.`
      );
    }
  }

  startPostingData() {
    // Clear any existing interval
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.intervalId = setInterval(() => {
      if (this.analyserNode && this.stream) {
        this.analyserNode.getFloatTimeDomainData(this.dataArray); // Get data from analyserNode
        const msg = {
          input: this.dataArray,
          sampleRate: LocalPlayer.audioContext.sampleRate,
        };
        this.worker.postMessage(msg); // Post data to the worker
      }
    }, this.intervalTime);
  }

  stopPostingData() {
    // Clear the interval when no longer needed
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}

// Create a reactive instance of PlayerManager
const pm = reactive(new PlayerManager());
export default pm;
